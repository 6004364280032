import React, {ReactElement, useContext} from "react";
import {Button, Grid, NumberField, View} from "@adobe/react-spectrum";
import {Button as RButton} from 'react-aria-components';
import {calculatePrintingPrice, PaperSize, PaperType, PrintLayout, PrintPrices, PrintType} from "./Pricing";
import {ConfigContext} from "./PriceCalculatorPage";
import "./CheckoutScreen.css"
import {defaultJob, PrintJob, PrintJobs} from "./Types";
import Add from "@spectrum-icons/workflow/Add";

export function CheckoutScreen(): ReactElement {
  const prices: PrintPrices = useContext(ConfigContext);
  const rows: Array<ReactElement> = []
  const printjobCtx = useContext(PrintJobs)

  printjobCtx?.jobs.forEach(
    (job, index) => {
      const price = calculatePrintingPrice(job, prices)
      rows.push(
        <Grid key={index}
          id={`${index}`}
          height="10vhs"
          width="100%"
          columns={{
            base: ["0.5fr", "1fr"],
            S: ["0.5fr", "1fr", "1fr"]
          }}
          areas={
            {
              base: [
                "name name",
                "preview amount",
                "preview price",
                "preview remove",
              ],
              S: [
                "preview amount price",
                "preview remove name"
              ]
            }
          }
          UNSAFE_className={"bottom-border"}
        >
          <Button aria-label={"verwijderen"} gridArea="remove" variant={"secondary"} onPress={(e) => {
            const newJobs: PrintJob[] = Object.assign([], printjobCtx.jobs);
            delete newJobs[index];
            printjobCtx.setJobs(newJobs.filter(x => x != undefined))
          }}>Verwijderen</Button>
          <View gridArea="name">{job.document.name}</View>
          <View gridArea="amount" paddingStart={"5px"} paddingEnd={"5px"}>
          <NumberField aria-label={"hoeveelheid"} justifySelf="center" width={"90%"} minValue={0} onChange={v => {
            const jobs: PrintJob[] = Object.assign([], printjobCtx.jobs);
            jobs[index].numberOfDocuments = v
            printjobCtx.setJobs(jobs)
          }} defaultValue={job.numberOfDocuments}></NumberField>
          </View>
          <View gridArea="price" UNSAFE_className="price small">{price?.toRoundedUnit(2) + " €"}</View>
          <Button gridArea="preview" variant={"secondary"} onPress={e => printjobCtx.setFocus({jobId: index})} height="100%">
            <img className={"preview soft"} height="100vhs"
                 src={job.document.previewImg}/>

          </Button>
        </Grid>
      )
    }
  )

  const addJob = () => {
    const nextId = printjobCtx.jobs.length
    const newJobs: PrintJob[] = Object.assign([], printjobCtx.jobs);
    newJobs.push(defaultJob())
    printjobCtx.setJobs(newJobs)
    printjobCtx.setFocus({jobId: nextId})
  }

  rows.push(
    <View UNSAFE_className={"sizeSvg"} width={"100%"} id={"add-item"}>
    <Button key={rows.length} marginTop={"5px"} height={"7vh"} width={"100%"} variant={"secondary"} onPress={e => addJob()} >
      <Add />
    </Button>
    </View>
  )
// @ts-ignore
  return rows
}