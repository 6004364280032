import { CopyPrice, PagePrice } from "./Pricing";
import DineroFactory, { Dinero } from "dinero.js";

export type Config = {
  host: string,
  vat: number,
  copyPrice: Array<CopyPrice>;
  pagePrice: Array<PagePrice>;
};

export async function loadConfig(): Promise<Config> {
  const response = await fetch("Config.json");
  // @ts-ignore
  const value: Config = await response.json();
  const convert = (price: string) => {
    const strings = price.split(".");
    var priceInt: number;
    if (strings.length == 1) {
      priceInt = Number(strings[0] + "0000");
    } else {
      priceInt = Number(
        strings[0] + strings[1] + "0".repeat(4 - strings[1].length),
      );
    }
    // @ts-ignore
    return DineroFactory({ amount: priceInt, precision: 4, currency: "EUR" });
  };

  return {
    host: value.host,
    vat: value.vat,
    copyPrice: value.copyPrice.map((x) => {
      // @ts-ignore
      x.price = convert(x.price);
      return x;
    }),
    pagePrice: value.pagePrice.map((x) => {
      // @ts-ignore
      x.price = convert(x.price);
      return x;
    }),
  };
}
