import {PagesInDocument, PaperSize, PaperType, PrintLayout, PrintType} from "./Pricing";
import {Document} from "./PdfDropZone";
import React, {Dispatch} from "react";

export interface PrintJob {
  numberOfDocuments: PagesInDocument;
  paperType: PaperType;
  paperSize: PaperSize;
  printType: PrintType;
  printLayout: PrintLayout;
  document: Document;
}

export type OrderId = string
export type UploadLink = string
export type CheckoutLink = string


export interface ShowJob {
  jobId: number
}

export interface ShowCheckout {
  checkoutScreen: number
}

export interface ShowCustomerPage {
  customerDetails: number
}

export interface ShowUploading {
  uploading: number
}

export interface JobsValue {
  jobs: PrintJob[]
  setJobs: Dispatch<PrintJob[]>
  setFocus: Dispatch<ShowJob | ShowCheckout>
}

export type Email = string
let emailRegex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$", "i");
export function isValidEmail(s :string): boolean {
  return emailRegex.test(s)
}

export function EmailFromString(s : string) : Email | null {
  if (emailRegex.test(s)) {
    return s
  } else {
    return null
  }
}

export interface Customer {
  firstName : string,
  lastName  : string,
  email     : Email ,
}


// @ts-ignore
export const PrintJobs: React.Context<JobsValue> = React.createContext(undefined);

export function defaultJob(): PrintJob {
  return {
    numberOfDocuments: 1,
    paperType: PaperType.Gram80,
    paperSize: PaperSize.A4,
    printType: PrintType.LaserBW,
    printLayout: PrintLayout.Recto,
    document: {
      name: "",
      pages: 0,
      // @ts-ignore
      data: null,
      previewImg: "img/pdf-svgrepo-com.svg"
    }
  }
}