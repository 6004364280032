import React from "react";
import "@spectrum-css/vars/dist/spectrum-light.css";
import "./App.css";
import {Grid, View} from "@adobe/react-spectrum";
import {Text} from "react-aria-components";
import {ToastContainer} from '@react-spectrum/toast'

function ResultPage(props: {text: string, headerBackground: string}) {
  return <div className="App">
    <Grid
      maxHeight={"100svh"}
      justifyContent="center"
      areas={{
        base: ["header header header", ". content .", "controls controls controls"],
        M: ["header header header header", ". content controls .", ". . . ."],
      }}
      columns={{
        base: ["1fr", "minmax(200px, 600px)", "1fr"],
        M: ["1fr", "minmax(0, 600px)", "minmax(0, 100px)", "1fr"]
      }}
      rows={{
        base: ["auto", "1fr", "auto"],
        M: ["auto", "1fr"]
      }}
    >
      {/* @ts-ignore */}
      <View backgroundColor={props.headerBackground} gridArea="header" marginBottom={10} padding={"1rem"}>
        <img src={"img/Copymatic-digital-printing-Antwerp-38.png"}/>
      </View>
      <View overflow="scroll" gridArea="content" marginTop="10px" id="page" justifySelf={"center"} width="80%">
        <Text>{props.text}</Text>
      </View>
      <View gridArea="controls" marginTop={10}>
      </View>
    </Grid>
    <ToastContainer/>
  </div>
}

export function Failure() {
  return <ResultPage text={"Uw betaling is gefaald"} headerBackground={"red-600"}/>
}

export function Success() {
    return <ResultPage text={"uw betaling is gelukt"} headerBackground={"celery-600"}/>
}
