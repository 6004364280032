import React, { createRef, useEffect } from "react";

import { Button, FileTrigger } from "react-aria-components";
import type { FileDropItem } from "react-aria";
import "./PdfDropZone.css";

import * as pdfjs from "pdfjs-dist";
// @ts-ignore
import pdfJSWorkerURL from "pdfjs-dist/build/pdf.worker?url";
import { DropZone } from "@react-spectrum/dropzone";
import { Flex,Text } from "@adobe/react-spectrum";

pdfjs.GlobalWorkerOptions.workerSrc = pdfJSWorkerURL;

export type Document = {
  name: string;
  data: File;
  pages: number;
  previewImg: string;
};

const createOffScreenCanvas = (): HTMLCanvasElement => {
  const offScreenCanvas: HTMLCanvasElement = document.createElement("canvas");
  offScreenCanvas.width = 210 * 4;
  offScreenCanvas.height = 297 * 4;
  return offScreenCanvas;
};

const OffScreenCanvas = createOffScreenCanvas();

export function PdfDropzone(props: {
  defaultImage: string| undefined;
  onDocument: (document: Document) => void;
}) {
  const [text, setText] = React.useState<string>("Upload Pdf");
  const [previewUrl, setPreviewUrl] = React.useState<string>(
    props.defaultImage || "img/pdf-svgrepo-com.svg",
  );

  const handleFile = (f: File) => {
    getPageCount(f, OffScreenCanvas).then((document) => {
      if(document){
        props.onDocument(document);
        setPreviewUrl(document.previewImg)
      }
    });
  };

  return (
    <DropZone
      onDrop={(e) => {
        let files = e.items.filter(
          (file) => file.kind === "file" && file.type == "application/pdf",
        ) as FileDropItem[];
        if (files.length > 0) {
          let filenames = files[0];
          setText(filenames.name);
          filenames.getFile().then(handleFile);
        } else {
          setText("alleen .pdf files zijn ondersteund");
        }
      }}
    >
      <FileTrigger
        allowsMultiple={false}
        acceptedFileTypes={["application/pdf"]}
        onSelect={(e) => {
          // @ts-ignore
          let files = Array.from(e);
          if (files.length > 0) {
            handleFile(files[0]);
            setText(files[0].name);
          }
        }}
      >
        <Button className="react-aria-DropZone react-aria-Button">
          <Flex direction="column" justifyContent="center" alignItems="center" height="80%">
            <img
              className="preview soft"
              width="70%"
              src={previewUrl}
              id={"pdf-preview"}
              alt="Upload een PDF"
            />
            <Text UNSAFE_className={"ellipsis-text"}>{text}</Text>
          </Flex>
        </Button>
      </FileTrigger>
    </DropZone>
  );
}

export async function getPageCount(
  fileName: File,
  canvas: HTMLCanvasElement,
): Promise<Document | undefined> {
  if (fileName == undefined) {
    return ;
  }

  const data = new Uint8Array(await fileName.arrayBuffer());
  const loadingDoc = pdfjs.getDocument(data);
  const doc = await loadingDoc.promise;
  const numPages = doc.numPages;

  // @ts-ignore this should never be null
  const context: CanvasRenderingContext2D = canvas.getContext("2d");

  const page = await doc.getPage(1);
  const desiredWidth = canvas.width;
  const viewport = page.getViewport({ scale: 1 });
  const scale = desiredWidth / viewport.width;
  console.log("rendering the page");
  // @ts-ignore
  await page.render({
    canvasContext: context,
    viewport: page.getViewport({ scale: scale }),
  }).promise;

  await doc.destroy();
  return { name: fileName.name, data: fileName, pages: numPages, previewImg: OffScreenCanvas.toDataURL("image/jpeg", "0.8") };
}
