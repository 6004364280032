import {Document, PdfDropzone} from "./PdfDropZone";
import {calculatePrintingPrice, PaperSize, PaperType, PrintLayout, PrintPrices, PrintType,} from "./Pricing";
import React, {ReactElement, useContext} from "react";
import {Config} from "./Config";

import {Flex, NumberField, View} from "@adobe/react-spectrum";
import {Card, CardGroup} from "./CardGroup";

import {Text} from "react-aria-components";
import {Dinero} from "dinero.js";
import {PrintJob} from "./Types";

export const ConfigContext = React.createContext<Config>({
  host: "",
  vat: 0,
  copyPrice: Array(),
  pagePrice: Array(),
});
type Price = string
type PriceDeltas = Map<PrintLayout | PrintType | PaperSize, Price>

export function PriceCalculator(props: {
  printJob: PrintJob,
  setPrintJob: React.Dispatch<PrintJob>
}) {
  console.log(props)
  const printJob = props.printJob
  const setPrintJob = props.setPrintJob

  const prices = CalculatePriceVariants(printJob);

  const setDocumentCount = (n: number) => {
    setPrintJob({...printJob, numberOfDocuments: n})
  }

  const setPagesPerDocument = (n: Document | null) => {
    if (n != null) {
      setPrintJob({...printJob, document: n})
    }
  }

  const setPaperSize = (n: PaperSize) => {
    setPrintJob({...printJob, paperSize: n})
  }

  const setPrintType = (n: PrintType) => {
    setPrintJob({...printJob, printType: n})
  }

  const setPrintLayout = (n: PrintLayout) => {
    setPrintJob({...printJob, printLayout: n})
  }


  return (
    <Flex direction="column" justifyContent="center" minWidth="size-1000">
      <View maxWidth="600px">
        <PdfDropzone
          defaultImage={props.printJob.document?.previewImg}
          onDocument={setPagesPerDocument}
        />
      </View>
      <PricingText className="priceText" job={printJob}/>
      <View>
        <NumberField
          label="Number Of Documents"
          defaultValue={props.printJob.numberOfDocuments}
          onChange={setDocumentCount}
          maxValue={10000}
          minValue={0}/>
      </View>
      <PageLayoutCards defaultValue={printJob.printLayout} onChange={setPrintLayout} priceDeltas={prices}/>
      <PrintTypeCards  defaultValue={printJob.printType} onChange={setPrintType} priceDeltas={prices}/>
      <PaperSizeCards  defaultValue={printJob.paperSize} onChange={setPaperSize} priceDeltas={prices}/>
    </Flex>
  );
}

const defaultPriceDelta = "error"
const PageLayoutCards = (props: { defaultValue: PrintLayout, onChange: (x: PrintLayout) => void, priceDeltas: PriceDeltas }): ReactElement => {
  return (
    <CardGroup
      label="Print Layout"
      defaultValue={props.defaultValue.valueOf()}
      onChange={(name: string) => props.onChange(name as PrintLayout)}>
      <Card label="Enkelzijdig" value={PrintLayout.Recto.valueOf()}
            priceDelta={props.priceDeltas.get(PrintLayout.Recto) || defaultPriceDelta}/>
      <Card label="Dubbelzijdig" value={PrintLayout.RectoVerso.valueOf()}
            priceDelta={props.priceDeltas.get(PrintLayout.RectoVerso) || defaultPriceDelta}/>
    </CardGroup>
  );
};

const PrintTypeCards = (props: {defaultValue: PrintType, onChange: (x: PrintType) => void, priceDeltas: PriceDeltas }): ReactElement => {
  return (
    <CardGroup
      label="Print Type"
      defaultValue={props.defaultValue.valueOf()}
      onChange={(name: string) => props.onChange(name as PrintType)}>
      <Card label="Zwart Wit" value={PrintType.LaserBW.valueOf()}
            priceDelta={props.priceDeltas.get(PrintType.LaserBW) || defaultPriceDelta}/>
      <Card label="Kleur " value={PrintType.LaserColor.valueOf()}
            priceDelta={props.priceDeltas.get(PrintType.LaserColor) || defaultPriceDelta}/>
    </CardGroup>
  );
};

const PaperSizeCards = (props: { defaultValue: PaperSize, onChange: (x: PaperSize) => void, priceDeltas: PriceDeltas }): ReactElement => {
  return (
    <CardGroup
      label="Print Type"
      defaultValue={props.defaultValue.valueOf()}
      onChange={(name: string) => props.onChange(name as PaperSize)}>
      <Card label="A4" value={PaperSize.A4.valueOf()} priceDelta={props.priceDeltas.get(PaperSize.A4) || ""}/>
      <Card label="A3" value={PaperSize.A3.valueOf()} priceDelta={props.priceDeltas.get(PaperSize.A3) || ""}/>
    </CardGroup>
  );
};

const PricingText = (props: { className?: string; job: PrintJob }) => {
  const prices: PrintPrices = useContext(ConfigContext);
  const price = calculatePrintingPrice(props.job, prices)

  var value: string
  if (price == null) {
    value = "error";
  } else {
    value = price.toRoundedUnit(2) + " €";
  }


  return (
    <div className={props.className}>
      <Text className={"priceText"}>{value}</Text>
    </div>
  );
};


const CalculatePriceVariants = (currentJob: PrintJob): PriceDeltas => {
  const prices: PrintPrices = useContext(ConfigContext);
  const calculatedPrices = new Map<PrintLayout | PaperSize | PrintType, Dinero | null>();

  const currentPrice = calculatePrintingPrice(currentJob, prices)

  for (const es in PrintLayout) {
    const e = PrintLayout[es as keyof typeof PrintLayout]
    calculatedPrices.set(e, calculatePrintingPrice({...currentJob, printLayout: e}, prices))
  }

  for (const es in PaperSize) {
    const e = PaperSize[es as keyof typeof PaperSize]
    calculatedPrices.set(e, calculatePrintingPrice({...currentJob, paperSize: e}, prices))
  }

  for (const es in PrintType) {
    const e = PrintType[es as keyof typeof PrintType]
    calculatedPrices.set(e, calculatePrintingPrice({...currentJob, printType: e}, prices))
  }

  const priceDifferences = new Map<PrintLayout | PaperSize | PrintType, Price>();
  calculatedPrices.forEach((v, k) => {
      if (v != null && currentPrice != null) {
        const difference = v.subtract(currentPrice).toRoundedUnit(2)
        let priceString = `${difference} €`
        if (difference > 0) {
          priceString = "+ " + priceString
        }

        priceDifferences.set(k, priceString)
      }
    }
  )

  return priceDifferences
}