import React, {ReactElement, useEffect, useState} from "react";
import {Config, loadConfig} from "./Config";
import {ConfigContext, PriceCalculator} from "./PriceCalculatorPage";
import "@spectrum-css/vars/dist/spectrum-light.css";
import "./App.css";
import {CheckoutScreen} from "./CheckoutScreen";
import {totalPrice} from "./Pricing";
import {
  Customer,
  defaultJob,
  PrintJob,
  PrintJobs,
  ShowCheckout,
  ShowCustomerPage,
  ShowJob,
  ShowUploading
} from "./Types";
import {Button, Flex, Grid, ProgressCircle, View} from "@adobe/react-spectrum";
import {Text} from "react-aria-components";
import {CustomerDetailsScreen} from "./CustomerDetailsScreen";
import {Backend, OrderProgress} from "./Backend";
import {ToastContainer, ToastQueue} from '@react-spectrum/toast'

function App() {
  const [config, setConfig] = useState<Config>({
    host: "",
    vat: 0,
    copyPrice: Array(),
    pagePrice: Array(),
  });

  useEffect(() => {
    if (config.pagePrice.length == 0) {
      loadConfig().then(setConfig);
    }
  });


  const [jobs, setJobs] = useState<PrintJob[]>([
    defaultJob()
  ]);

  const [focus, setFocus] = useState<ShowJob | ShowCheckout | ShowCustomerPage | ShowUploading>({jobId: 0})

  const [customer, setCustomer] = useState<Customer>();

  const [uploadProgress, setUploadProgress] = useState<OrderProgress>()

  let actionButton : ReactElement = <View></View>
  let page: ReactElement = <View></View>
  if ("checkoutScreen" in focus) {
    actionButton = <Button variant="accent" onPress={e=> {
      setFocus({customerDetails: 0})
    }}>Bestellen</Button>
     page = <CheckoutScreen/>
  } else if ("jobId" in focus) {
    actionButton = <Button variant="accent" onPress={e=> {
      const newJobs: PrintJob[] = Object.assign([], jobs);
      setJobs(newJobs.filter(x => x.document.name != ""))
      setFocus({checkoutScreen: 0})
    }}>Checkout</Button>
    page = <PriceCalculator printJob={jobs[focus.jobId]} setPrintJob={ (printJob :PrintJob) => {
        const newJobs: PrintJob[] = Object.assign([], jobs);
        newJobs[focus.jobId] = printJob
        setJobs(newJobs)
    }}/>
  } else if ("customerDetails" in focus) {
    page = <CustomerDetailsScreen onSubmit={ customer => {
      setCustomer(customer)
      setFocus({uploading: 1})
      const backend = new Backend(config.host)
      backend.handleOrder(jobs, customer, setUploadProgress)
        .then(url => window.location.replace(url))
        .catch(x => ToastQueue.negative(`we had a problem uploading the documents ${x}`))
    }} cancelOrder={_ => setFocus({checkoutScreen: 0})}/>
  } else if ("uploading" in focus) {
    let label: string = ""
    let progress: number = 0
    if (uploadProgress == undefined){

    } else if ("zippingFiles" in uploadProgress ){
      label = "de files worden gezipped"
      progress = uploadProgress.zippingFiles
    }
    else if ("uploadProgress" in uploadProgress ){
       label = "de files zijn aan het uploaden"
       progress = uploadProgress.uploadProgress + 100
    }
    else if ("validatingFiles" in uploadProgress ){
       label = "de order wordt gevalideerd"
       progress = uploadProgress.validatingFiles + 200
    }


    page = <Flex direction={"row"}>
      <ProgressCircle aria-label={label} maxValue={300} value={progress} />
      {label}
    </Flex>
  }

  return (
    <div className="App">
      <ConfigContext.Provider value={config}>
        <PrintJobs.Provider value={{setJobs: setJobs, jobs: jobs, setFocus: setFocus}}>
          <Grid
            maxHeight={"100svh"}
            justifyContent="center"
            areas={{
              base: ["header header header", ". content .", "controls controls controls"],
              M: ["header header header header", ". content controls .", ". . . ."],
            }}
            columns={{
              base: ["1fr", "minmax(200px, 600px)", "1fr"],
              M: ["1fr", "minmax(0, 600px)", "minmax(0, 100px)", "1fr"]
            }}
            rows={{
              base: ["auto", "1fr", "auto"],
              M: ["auto", "1fr"]
            }}
          >
            <View backgroundColor="gray-400" gridArea="header" marginBottom={10} padding={"1rem"}>
              <img src={"img/Copymatic-digital-printing-Antwerp-38.png"} />
            </View>
            <View overflow="scroll" gridArea="content" marginTop="10px" id="page" justifySelf={"center"} width="80%">
              {page}
            </View>
            <View gridArea="controls" marginTop={10}>
              {actionButton}
              <Text className={"priceText"}>{ totalPrice(jobs, config).toRoundedUnit(2)+ " €"}</Text>
            </View>
          </Grid>
          <ToastContainer />
        </PrintJobs.Provider>
      </ConfigContext.Provider>
    </div>
  );
}

export default App;
