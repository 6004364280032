import React, {ReactElement, ReactNode} from "react";
import type {RadioGroupProps, RadioProps} from "@react-types/radio";
import {Radio, RadioGroup} from "react-aria-components";

import {Flex, Grid, Text, View} from "@adobe/react-spectrum";
import Checkmark from "@spectrum-icons/workflow/Checkmark";

import "./CardGroup.css";

interface CardGroupProps extends Omit<RadioGroupProps, "children"> {
  label: String;
  // todo can we force this as a card?
  children: ReactNode;
}

export function CardGroup(props: CardGroupProps): ReactElement {
  let {children, label} = props;

  return (
    // @ts-ignore
    <RadioGroup {...props} aria-label={props.label}>
      <View width="100%">
        <Flex width="100%" justifyContent="center" >
          <Text UNSAFE_className={"CardGroupLabel"}>{label}</Text>
        </Flex>
        <Flex justifyContent="center" wrap="wrap" marginStart="10px" marginEnd="10px">
          {children}
        </Flex>
      </View>
    </RadioGroup>
  );
}

interface CardProps extends RadioProps {
  label: string;
  priceDelta: string;
}

export function Card(props: CardProps) {
  return (
    <Radio value={props.value} className={"Card"}>
      <Grid
        columns={["5%", "90%"]}
        rows={["50%", "50%"]}
        areas={["checkmark label", "checkmark price"]}
        height="100%"
        alignItems="center"
      >
        <View gridArea="checkmark">
        <Checkmark size={"S"} UNSAFE_className={"CheckMark"}/>
        </View>
        <View gridArea="label">
          <Text UNSAFE_className={"CardLabel"}>{props.label}</Text>
        </View>
        <View gridArea="price" UNSAFE_className={"PriceDelta"}>
          {props.priceDelta}
        </View>
      </Grid>
    </Radio>
  );
}
