import DineroFactory, { Dinero } from "dinero.js";
import {JobsValue, PrintJob} from "./Types";

export { calculateCopyPrice, calculatePaperPrice, calculatePrintingPrice };
export enum PrintType {
  LaserBW = "laser-bw",
  LaserColor = "laser-color",
  //  there is also inkt jet and the color plotter.
}

export enum PaperSize {
  A3 = "A3",
  A4 = "A4",
}

export enum PaperType {
  Gram80 = "white-80g",
  Gram120 = "white-120g",
  Gram160 = "white-160g",
  Gram250 = "white-250g",
  Gram300 = "white-300g",
}

export enum PrintLayout {
  Recto = "recto",
  RectoVerso = "recto-verso",
}

export type PageCount = number;
export type PagesInDocument = number;
export type NumberOfDocuments = number;
export type SheetsOfPaper = number;
export type PrinterClicks = number;

export interface PrintPrices {
  vat: number;
  copyPrice: Array<CopyPrice>;
  pagePrice: Array<PagePrice>;
}

export interface CopyPrice {
  from: PageCount;
  printType: PrintType;
  paperSize: PaperSize;
  price: Dinero;
}

export interface PagePrice {
  from: PageCount;
  paperType: PaperType;
  paperSize: PaperSize;
  price: Dinero;
}

function sheetsOfPaper(
  pagesInDocument: PagesInDocument,
  printSides: PrintLayout,
): SheetsOfPaper {
  if (printSides == PrintLayout.RectoVerso) {
    return Math.floor((pagesInDocument + 1) / 2);
  } else {
    return pagesInDocument;
  }
}

function printerClicks(
  pagesInDocument: PagesInDocument,
  printSides: PrintLayout,
): PrinterClicks {
  if (printSides === PrintLayout.Recto) {
    return pagesInDocument;
  }

  if (pagesInDocument % 2 === 1) {
    return pagesInDocument + 1;
  }

  return pagesInDocument;
}

function calculateCopyPrice(
  input: PrintJob,
  priceList: Array<CopyPrice>,
): Dinero {
  const numberOfSheets = printerClicks(
    input.document.pages,
    input.printLayout,
  );
  const totalPages = numberOfSheets * input.numberOfDocuments;
  const priceBracket = priceList
    .filter(
      (pricing) =>
        pricing.paperSize == input.paperSize &&
        pricing.printType == input.printType &&
        pricing.from <= totalPages,
    )
    // min by from
    .reduceRight((maxValue, currentValue) => {
      if (currentValue.from > maxValue.from) {
        return currentValue;
      } else {
        return maxValue;
      }
    });
  return priceBracket.price.multiply(totalPages);
}

function calculatePaperPrice(
  input: PrintJob,
  priceList: Array<PagePrice>,
): Dinero {
  const numberOfSheets = sheetsOfPaper(
    input.document.pages,
    input.printLayout,
  );
  const totalPages = numberOfSheets * input.numberOfDocuments;
  const priceBracket = priceList
    .filter(
      (pricing) =>
        pricing.paperSize == input.paperSize &&
        pricing.paperType == input.paperType &&
        pricing.from <= totalPages,
    )
    .reduceRight((maxValue, currentValue) => {
      if (currentValue.from > maxValue.from) {
        return currentValue;
      } else {
        return maxValue;
      }
    });

  return priceBracket.price.multiply(totalPages);
}

function calculatePrintingPrice(
  input: PrintJob,
  price: PrintPrices,
): Dinero | null {
  try {
    const copyPrice = calculateCopyPrice(input, price.copyPrice);
    const pagePrice = calculatePaperPrice(input, price.pagePrice);
    console.log("prices", {"vat": price.vat, "copy-price": copyPrice.getAmount(), "print-price": pagePrice.getAmount()});
    return copyPrice.add(pagePrice).multiply(price.vat).divide(100);
  } catch (e) {
    return null;
  }
}



export const totalPrice = (jobs: PrintJob[], pricing: PrintPrices): Dinero  => {
  return jobs.reduce((total, job) => {
    const price = calculatePrintingPrice(job, pricing)
    if (price != null) {
      return total.add(price)
    } else {
      return total
    }
  }, DineroFactory({ amount: 0, precision: 4, currency: "EUR" }))
}