import React, {ReactElement, useState} from "react";
import {Button, ButtonGroup, Flex, Form, View} from "@adobe/react-spectrum";
import "./OrderScreen.css"
import {TextField} from "@react-spectrum/textfield";
import {Customer, isValidEmail} from "./Types";

export function CustomerDetailsScreen(props: {onSubmit: React.Dispatch<Customer>, cancelOrder: React.Dispatch<any>}): ReactElement {
  return<Form
      validationBehavior={"native"}
      onSubmit={e => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
          firstName: { value: string };
          lastName: { value: string };
          email: { value: string };
        };
        props.onSubmit(
          {
            firstName: target.firstName.value,
            lastName: target.lastName.value,
            email: target.email.value,
          }
        )
      }}
    >
      <TextField name="firstName" label={"First Name"} isRequired validate={x => true}/>
      <TextField name="lastName" label={"Last Name"} isRequired validate={x => true}/>
      <TextField name="email" label={"Email"} isRequired
                 validate={x => !isValidEmail(x) ? "the email is invalid" : true}/>
      <ButtonGroup width={"100%"}>
        <Button type="submit" variant="primary">Betalen</Button>
        <Button type="button" variant="negative" onPress={e => props.cancelOrder(e)}>Cancel</Button>
      </ButtonGroup>
    </Form>
}