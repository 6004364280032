import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { defaultTheme, Provider } from "@adobe/react-spectrum";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import {Failure, Success} from "./Result";

const router = createBrowserRouter([
  {
    path: "/",
    element:  <App/>,
  },
  {
    path: "/success",
    element:  <Success/>,
  },
  {
    path: "/failure",
    element:  <Failure/>,
  },
]);
// @ts-ignore
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider theme={defaultTheme}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
