import {CheckoutLink, Customer, OrderId, PrintJob, UploadLink} from "./Types";

import * as zip from "@zip.js/zip.js";
import {ToastQueue} from "@react-spectrum/toast";
import axios from "axios";

export interface CreateOrderRequest {
}

export interface CreateOrderResponse {
  orderId: OrderId,
  uploadUrl: UploadLink
}

export interface PayOrderRequest {
  orderId: OrderId
  customer: Customer
}

export interface PayOrderResponse {
  checkoutUrl: CheckoutLink
}

type UploadPercentage = number
export type OrderProgress = ZippingFiles|UploadProgress|ValidatingFiles
//upload progress in percent
export interface ZippingFiles {
  zippingFiles: number
}

export interface UploadProgress {
  uploadProgress: UploadPercentage
}

export interface ValidatingFiles {
  validatingFiles: number
}

export class Backend {
  host: string;
  constructor(host :string) {
    console.log("creating backend to ", host)
    this.host= host
  }

  createOrder(): Promise<CreateOrderResponse> {
    return fetch(`${this.host}/default/create-order`, {method: "POST"})
      .then(x => x.json())
      .then(x => x as CreateOrderResponse)
  }

  createOrderZip(printJobs: PrintJob[]):Promise<Blob> {
    const zipFileWriter = new zip.BlobWriter();
    const writer = new zip.ZipWriter(zipFileWriter)
    let wait: Promise<any> = Promise.resolve()
    printJobs.forEach((job,index) =>
      wait = wait.then(_ => writer.add(index.toString() +"-"+ job.document.name, job.document.data.stream()))
    )
    let metadata = printJobs.map((job, index) => ({
        numberOfDocuments: job.numberOfDocuments,
        pagesPerDocument: job.document.pages,
        paperType: job.paperType,
        paperSize: job.paperSize,
        printType: job.printType,
        printLayout: job.printLayout,
        documentId: index.toString() +"-"+ job.document.name,
      })
    )
    return wait.then(_ => writer.add("metadata.json",  new zip.TextReader(JSON.stringify({jobs: metadata}))))
      .then(_ => writer.close())
  }

  uploadOrder(uploadUrl: UploadLink, blob:Blob, uploadProgress : ((progress: UploadPercentage) => void)): Promise<any> {
      return axios(uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/zip',
          'Content-Length': blob.size.toString(),
        },
        onUploadProgress: (progressEvent => Math.round((progressEvent.loaded * 100) / (progressEvent.total || blob.size))),
        data: blob
      })
  }

  payOrder(order: OrderId, customer: Customer): Promise<PayOrderResponse> {
    return fetch(`${this.host}/default/pay-order`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderId: order,
        customer: customer,
        })})
      .then(r => r.json())
      .then(r => r as PayOrderResponse)
  }

  handleOrder(printJobs: PrintJob[], customer: Customer, progress : ((progress: OrderProgress) => void)): Promise<CheckoutLink> {
    progress({zippingFiles:0})
    return Promise.all([this.createOrder(),  this.createOrderZip(printJobs)])
      .then(([order, printZip]) =>
        this.uploadOrder(order.uploadUrl,printZip, (x) => progress({uploadProgress: x}))
        .then(_ => this.payOrder(order.orderId, customer))
      )
      .then(x => x.checkoutUrl)
  }
}
